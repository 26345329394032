import React from "react";
import '../styles/styles.css';

//Components
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const Error = () => {
    return (
        <>
            <Navbar/>
            <div className='flex flex-col-reverse mt-24 my-4 p-3 justify-center md:flex-row md:mt-40'>
                <h1>An error occured</h1>
            </div>
            <Footer />
        </>
    )
}

export default Error